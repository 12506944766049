import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import API from '../../api/axios';
export default defineComponent({
  name: "News",
  setup: function setup() {
    var bannerList = ref([{
      url: require("../../assets/banner.jpg")
    } // {
    //   url: require("../../assets/banner2.jpg"),
    // },
    ]);
    var active = ref(0);

    var gotoBaicheng = function gotoBaicheng() {
      active.value = 0;
    };

    var gotoYejie = function gotoYejie() {
      active.value = 1;
    };

    var dataList = ref([]);

    var getData = function getData() {
      API({
        url: 'https://picbook.bcepd.cn/d-bcepd/cmpcms/userfree/pageListChannel',
        method: 'POST',
        params: {
          site_id: 'bcepdsite',
          channel_id: '1752150613026210731',
          page: 1,
          pagesize: 99
        }
      }).then(function (res) {
        // console.log("res", res.data.r.rs);
        dataList.value = res.data.r.rs;
      });
    };

    getData();
    var router = useRouter();

    var handleNewsdetail = function handleNewsdetail(e) {
      console.log("eee", e);
      router.push({
        path: '/newsdetail',
        query: {
          id: "".concat(e)
        }
      });
    };

    var handlebackTop = function handlebackTop() {
      content.scrollIntoView();
    };

    onMounted(function () {
      handlebackTop();
    });
    return {
      bannerList: bannerList,
      active: active,
      gotoBaicheng: gotoBaicheng,
      gotoYejie: gotoYejie,
      dataList: dataList,
      handleNewsdetail: handleNewsdetail,
      handlebackTop: handlebackTop
    };
  }
});